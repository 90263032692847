.header {
    position: relative;
    display: flex;
    align-items: center;
    height: var(--navbar-height);
    padding: 0 .6rem;
    border-bottom: 1px solid var(--border);
    overflow-y: hidden;

    &_item {
        display: flex;
        &:last-child {
            flex: 1;
        }
    }

    &_icon {
        height: 2rem;
    }

    &_toggle {
        position: relative;
        &::before {
            content: '';
            height: 100%;
            width: 1px;
            background-color: var(--border);
            position: absolute;
            left: 0;
        }
    }
}

.header_toggle_button {
    width: var(--touch-size);
    height: var(--touch-size);
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    transition: 0.5s transform;
    background-color: var(--button-fade);
    &.open {
        transform: rotate(90deg);
    }
}