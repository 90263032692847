.sidebar_setting {
    height: inherit;
    transition: .5s width;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &.open {
        width: var(--sidebar-width);
        border-left: 1px solid var(--border);
    }

    &:not(.open) {
        width: 0;
        margin-right: -10rem;
    }
}

.sidebar_setting_container {
    position: absolute;
    top: var(--navbar-height);
    right: 0;
    height: calc(100vh - var(--navbar-height));
    overflow-x: hidden;
    background-color: var(--background);
    z-index: 100;
}

.sidebar_setting_header {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
    padding: 1.5rem;

    &_photo {
        padding: 0.3rem;
        border: 0.25rem solid var(--primary);
        border-radius: 50%;

        img {
            width: 5rem;
            height: 5rem;
            object-fit: cover;
            object-position: center;
            border-radius: inherit;
        }
    }

    &_detail {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 0.5rem;

        span {
            display: block;
            text-align: center;
            font-size: 0.9rem;
            line-height: 0.9rem;

            &:nth-child(3) {
                padding: 0.25rem 0.75rem;
                border-radius: 1rem;
                font-size: var(--font-size-sm);
                font-weight: bold;
                letter-spacing: 1px;
                background-color: var(--button-fade);
            }
        }
    }
}