.breadcrumbs {
    display: flex;
    align-items: center;
    padding: 0.25rem;

    &_item {
        padding: 0.75rem;
        text-transform: capitalize;
        letter-spacing: 1px;

        &.active {
            color: var(--primary);
        }

        &:not(.active) {
            cursor: pointer;
        }

        &:not(.active):hover {
            text-decoration: underline;
        }
    }
}