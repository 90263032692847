.event_edit_container {
    padding: 1rem;
}

.event_edit_header {
    &_title {
        font-size: var(--font-size-xl);
    }

    .stepper {
        margin: 0 0 1.25rem;
    }
}