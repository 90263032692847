@import './bootstrap/functions';
@import './bootstrap/variables';
@import './bootstrap/switch';

$themes: (
    'light': (
        'primary': #978DF5,
        'primary-hover': #7f77c3,
        'primary-fade': #eee8fe,
        'secondary': #00a9ac,
        'background': #FFF,
        'backdrop': #f1f3f4,
        'border': #dadce0,
        'text': #000,
        'text-fade': '#7d7c83',
        'button-fade': #F1F1F1,
        'dark-grey-fade': '#dedede',
        'success': '#28a745',
        'danger': '#dc3545',
        'gold': '#c5af70',
        'silver': '#888c8c',
        'bronze': '#c69d99',
    ),
    'dark': (
        'primary': $blue,
        'primary-hover': #2353d9,
        'primary-fade': #161b22,
        'secondary': #00a9ac,
        'background': #0d1117,
        'backdrop': #161b22,
        'border': #30363d,
        'text': #B2B5BE,
        'text-fade': '#7d7c83',
        'button-fade': #393D47,
        'dark-grey-fade': '#393D47',
        'success': '#28a745',
        'danger': '#dc3545',
        'gold': '#c5af70',
        'silver': '#888c8c',
        'bronze': '#c69d99',
    ),
);

$fontsizes: (
    'xl': 1.2rem,  'lg': 1rem,  'md': .9rem,
    'sm': .75rem,  'xs': .6rem
);

@each $name, $map in $themes {
    [data-theme=#{$name}] {
        @each $key, $value in $map {
            --#{$key}: #{$value};
        }
    }
}

@each $key, $value in $fontsizes {
    [data-fontsize=#{$key}] {
        --font-size-xl: calc(#{$value} * 1.5);
        --font-size-lg: calc(#{$value} * 1.2);
        --font-size: #{$value};
        --font-size-sm: calc(#{$value} * .8);
    }
}

[data-fullscreen=true] {
    --navbar-height: 0px;
    --breadcrumb-height: 0px;
}

[data-fullscreen=false] {
    --navbar-height: 3.7rem;
    --breadcrumb-height: 2.5rem;
}

:root {
    --touch-size: 2.4rem;
    --sidebar-width: 20rem;
    --btn-size-height: 2.5rem;
}

.btn-primary {
    --bs-btn-bg: var(--primary) !important;
    --bs-btn-hover-bg: var(--primary-hover) !important;
    --bs-btn-border-color: var(--border) !important;
}
