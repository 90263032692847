.stepper {
    display: flex;
    gap: 3.5rem;

    &_item {
        position: relative;
        display: flex;
        align-items: center;
        gap: 1rem;

        &.active {
            .stepper_item_icon {
                background-color: var(--primary);
                > span {
                    color: white;
                }
            }

            .stepper_item_label {
                color: var(--primary);
            }
        }
    
        &:not(.active) {
            .stepper_item_icon  {
                background-color: #ece9e9;
                > span {
                    color: var(--text-fade);
                }
            }

            .stepper_item_label {
                color: var(--text-fade);
            }
        }

        &:not(:last-child):after {
            position: absolute;
            top: -0.1rem;
            right: -2.1rem;
            font-size: 1.5rem;
            font-family: serif;
            content: "\203A";
            transition: transform .1s;
            color: var(--text-fade);
        }
    }

    &_item_icon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 2.25rem;
        height: 2.25rem;
        border-radius: 0.25rem;
        > span {
            font-size: var(--font-size-md);
        }
    }
}