.form_event_ministry_type {
    background-color: var(--background);
    
    &_header {
        padding: 1rem;
        background-color: var(--primary);
        text-align: center;
        border-top-left-radius: 0.25rem;
        border-top-right-radius: 0.25rem;

        h2 {
            font-weight: bold;
            font-size: var(--font-size-md);
            color: white;
            text-transform: uppercase;
            letter-spacing: 2px;
        }
    }

    &_content {
        padding: 1rem;

        .ministry_type_container {
            overflow-y: auto;
            max-height: calc(100vh - 30rem);
            min-height: 10rem;

            h4 {
                letter-spacing: 1px;
            }
        }

        .ministry_type_card {
            border-bottom: 1px solid var(--border);

            > span {
                display: block;
            }
        }
    }

    &_footer {
        display: flex;
        justify-content: flex-end;
        gap: 0.25rem;
        width: 100%;
        padding: 1rem;
    }
}