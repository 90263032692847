.ministry_setup_edit {
    &_container {
        padding: 0 1rem 1rem;
    }

    &_content {
        padding: 1rem;
        margin-bottom: 1rem;
        background-color: var(--background);
        border-radius: 0.25rem;

        &_header {
            margin-bottom: 1rem;

            h4 {
                font-size: var(--font-size-lg);
                letter-spacing: 1px;
            }
        }
    }

    &_validate {
        position: relative;
    
        .form-control {
            position: absolute;
            bottom: 5rem;
            left: 0;
            z-index: -1;
            border: none !important;
            color: transparent !important;
        }
    }

    &_editor {
        max-height: calc(100vh - 34rem);
        overflow-y: auto;
        border-bottom: 1px solid var(--border);
    }
}

.ministry_setup_edit_question {
    position: relative;

    &_checkbox {
        .input-group {
            position: relative;
            max-width: 40rem;

            > button.form_delete_toggle {
                display: none;
                position: absolute;
                top: 1px;
                right: 1px;
                width: calc(2.5rem - 2px);
                height: calc(2.5rem - 2px);
                background-color: var(--background);
                z-index: 99;

                > span.material-symbols-outlined {
                    color: var(--danger);
                }
            }

            &:hover {
                > button.form_delete_toggle {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }
        }
    }

    &_file {
        select {
            width: auto;
        }
    }

    &_validate {
        position: absolute;
        bottom: 0.5rem;
        right: 1rem;
        width: calc(100% - 1rem) !important;
        z-index: 0;
        border: none !important;
        color: transparent !important;
    }

    .invalid-feedback {
        position: absolute;
        bottom: 1.05rem;
        right: 3.5rem;
        text-align: right;
    }

    & > div:not(.invalid-feedback) {
        position: relative;
        z-index: 1;
    }
}

.ministry_setup_edit_question_container {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}

.ministry_setup_edit_question_item {
    display: flex;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;

    &_draggable {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 1rem;
        
        cursor: move; /* fallback if grab cursor is unsupported */
        cursor: grab !important;
        cursor: -moz-grab;
        cursor: -webkit-grab;

        span {
            font-size: var(--font-size-xl);
        }

        &:active {
            cursor: grabbing !important;
            cursor: -moz-grabbing;
            cursor: -webkit-grabbing;
        }
    }

    &_input {
        flex: 1;
        padding: 1rem;
        border: 1px solid var(--border);
        border-radius: 0.25rem;
    }
}