.sidebar_menu {
    position: relative;
    height: inherit;
    background-color: var(--background);
    border-right: 1px solid var(--border);
    z-index: 99;

    > div {
        max-width: 100vw;
        padding: .5rem;
        overflow-x: hidden;
        overflow-y: auto;
        transition: max-width 0.5s ease-in-out;
    }

    .sidebar_menu_toggle {
        position: absolute;
        bottom: 2rem;
        right: -.9rem;
        width: 1.75rem;
        height: 1.75rem;
        background-color: var(--backdrop);
        border: 1px solid var(--border);
        border-radius: 50%;
        &::after {
            position: absolute;
            top: .25rem;
            left: .6rem;
            content: "\276F";
            font-size: .75rem;
            transition: transform 0.5s;
            transform: rotate(180deg);
        }
    }

    .sidebar_menu_item {
        position: relative;
        display: flex;
        align-items: center;
        margin-bottom: .25rem;
        padding-left: 0;
        padding-right: 0;
        cursor: pointer;
        border: none;
        border-radius: .25rem;
        background-color: transparent;
        gap: .5rem;

        &_icon {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 2.4rem;
            height: 2.4rem;
            transition: 0.5s;
        }

        &.active span {
            color: var(--primary);
        }

        span {
            font-size: 1rem;
        }
    }

    &.open {
        .sidebar_menu_item {
            &:hover, &.active {
                background-color: var(--primary-fade);
            }
        }

        .sidebar_menu_group_label {
            padding: 1rem 0.8rem 0.5rem;
            font-size: var(--font-size-sm);
            font-style: italic;
            color: var(--text-fade);
            text-transform: uppercase;
            letter-spacing: 1px;
            transition: 0.5s all;
        }
    }

    &:not(.open) {
        > div {
            max-width: 3.4rem;
        }

        .sidebar_menu_item {
            &_icon:hover,
            &.active .sidebar_menu_item_icon {
                background-color: var(--backdrop);
                border-radius: .25rem;
            }
        }

        .sidebar_menu_toggle::after {
            transform: rotate(0deg);
        }

        .sidebar_menu_group_label {
            height: 0;
            width: 0;
            opacity: 0;
        }
    }
}

@media screen and (max-width: 768px) {
    .sidebar_menu {
        position: fixed;
        left: 1px;
    }
}

@media screen and (min-width: 501px) {
    .sidebar_menu {
        .sidebar_menu_item {
            width: 15rem;
        }
    }
}

@media screen and (max-width: 500px) {
    .sidebar_menu {
        .sidebar_menu_item {
            width: calc(100vw - 2rem);
        }
    }
}