.ministry_setup_container {
    padding: 0 1rem 1rem;
}

.ministry_setup_header {
    margin-bottom: 1rem;
    &_title {
        font-size: var(--font-size-xl);
    }
}

.ministry_setup_content {
    position: relative;
    padding: 1rem;
    background-color: var(--background);
    border-radius: 0.25rem;

    &_header {
        display: flex;
        justify-content: space-between;
        margin-bottom: 1rem;

        &_title {
            font-size: var(--font-size-lg);
            letter-spacing: 1px;
        }

        .form-control {
            width: 15rem;
        }
    }

    .badge {
        padding: 0.5rem 0.75rem;
        text-transform: uppercase;
        letter-spacing: 1px;
    }

    .ministry_setup_table_action_bar {
        display: flex;
        gap: 0.25rem;
        flex-wrap: wrap;

        .btn {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 2rem;
            height: 2rem;
        }
    }

    &_loading {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: inherit;
        opacity: 0.8;

        span {
            letter-spacing: 1px;
        }
    }
}

@media screen and (min-width: 1841px) {
    .ministry_setup_content {
        .ministry_setup_table_action_bar {
            min-width: 20rem;
            max-width: 20rem;
        }
    }
}