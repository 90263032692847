.form_question {
    position: relative;

    &_checkbox {
        .input-group {
            position: relative;
            max-width: 40rem;

            > button.form_delete_toggle {
                display: none;
                position: absolute;
                top: 1px;
                right: 1px;
                width: calc(2.5rem - 2px);
                height: calc(2.5rem - 2px);
                background-color: var(--background);
                z-index: 99;

                > span.material-symbols-outlined {
                    color: var(--danger);
                }
            }

            &:hover {
                > button.form_delete_toggle {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }
        }
    }

    &_file {
        select {
            width: auto;
        }
    }

    &_validate {
        position: absolute;
        bottom: 0.5rem;
        right: 1rem;
        width: calc(100% - 1rem) !important;
        z-index: 0;
        border: none !important;
        color: transparent !important;
    }

    .invalid-feedback {
        position: absolute;
        bottom: 1.05rem;
        right: 3.5rem;
        text-align: right;
    }

    & > div:not(.invalid-feedback) {
        position: relative;
        z-index: 1;
    }
}

.form_event_question {
    background-color: var(--background);

    &_header {
        padding: 1rem;
        background-color: var(--primary);
        text-align: center;
        border-top-left-radius: 0.25rem;
        border-top-right-radius: 0.25rem;

        h2 {
            font-weight: bold;
            font-size: var(--font-size-md);
            color: white;
            text-transform: uppercase;
            letter-spacing: 2px;
        }
    }

    &_content {
        padding: 1rem;
    }

    &_footer {
        display: flex;
        justify-content: flex-end;
        gap: 0.25rem;
        width: 100%;
        padding: 1rem;
    }
}

.form_question_container {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}

.form_question_item {
    display: flex;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;

    &_draggable {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 1rem;
        
        cursor: move; /* fallback if grab cursor is unsupported */
        cursor: grab !important;
        cursor: -moz-grab;
        cursor: -webkit-grab;

        span {
            font-size: var(--font-size-xl);
        }

        &:active {
            cursor: grabbing !important;
            cursor: -moz-grabbing;
            cursor: -webkit-grabbing;
        }
    }

    &_input {
        flex: 1;
        padding: 1rem;
        border: 1px solid var(--border);
        border-radius: 0.25rem;
    }
}