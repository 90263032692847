.form_event_schedule {
    background-color: var(--background);
    
    &_header {
        padding: 1rem;
        background-color: var(--primary);
        text-align: center;
        border-top-left-radius: 0.25rem;
        border-top-right-radius: 0.25rem;

        h2 {
            font-weight: bold;
            font-size: var(--font-size-md);
            color: white;
            text-transform: uppercase;
            letter-spacing: 2px;
        }
    }

    &_content {
        display: flex;
        flex-wrap: wrap;
        gap: 1rem;
        padding: 1rem;
        border-bottom-left-radius: 0.25rem;
        border-bottom-right-radius: 0.25rem;

        > .form_group {
            width: calc(50% - 0.5rem);
        }
    }
}