.form_event_ministry_option {
    background-color: var(--background);
    
    &_header {
        padding: 1rem;
        background-color: var(--primary);
        text-align: center;
        border-top-left-radius: 0.25rem;
        border-top-right-radius: 0.25rem;

        h2 {
            font-weight: bold;
            font-size: var(--font-size-md);
            color: white;
            text-transform: uppercase;
            letter-spacing: 2px;
        }
    }

    &_content {
        display: flex;
        flex-wrap: wrap;
        padding: 1rem;
        border-bottom-left-radius: 0.25rem;
        border-bottom-right-radius: 0.25rem;

        &_header {
            display: flex;
            width: 100%;
            border: 1px solid var(--border);
            border-bottom: none;
            button {
                flex: 1;
                padding: 1rem;
                font-weight: bold;
                text-align: center;
                color: var(--primary);

                &.active {
                    background-color: var(--primary-fade);
                }
            }
        }

        &_list {
            overflow-y: auto;
            width: 100%;
            max-height: calc(100vh - 21.5rem);
            min-height: 10rem;
            margin-bottom: 1rem;
            border: 1px solid var(--border);

            .accordion > .accordion-item {
                border-left: none;
                border-right: none;
            }

            .accordion > .accordion-item:first-child {
                border-top: none !important;
            }

            .accordion > .accordion-item:last-child {
                border-bottom: none !important;
            }
        }

        > .form_group {
            width: calc(50% - 0.5rem);
        }
    }
}