.form_event_requirements {
  background-color: var(--background);

  &_header {
    padding: 1rem;
    background-color: var(--primary);
    text-align: center;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;

    h2 {
      font-weight: bold;
      font-size: var(--font-size-md);
      color: white;
      text-transform: uppercase;
      letter-spacing: 2px;
    }
  }

  &_content {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 1rem;
    border-bottom-left-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
  }

  &_validate {
    position: relative;

    .form-control {
      position: absolute;
      bottom: 5rem;
      left: 0;
      z-index: -1;
      border: none !important;
      color: transparent !important;
    }
  }

  &_editor {
    max-height: calc(100vh - 34rem);
    height: auto;
    overflow-y: auto;
    border-top: 1px solid var(--border);
    border-bottom: 1px solid var(--border);

    > div:nth-child(1) {
      border-top: none !important;
    }

    > div:nth-child(2) {
      border-bottom: none !important;
    }
  }
}
