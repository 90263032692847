@import url('https://fonts.googleapis.com/css2?family=Lato&display=swap');
@import 'mixins';
@import "./bootstrap/bootstrap";

// add global custom style below

* {
    font-family: sans-serif;
    font-weight: 400;
    font-style: normal;
    color: var(--text);
}

body {
    overflow-y: hidden;
    background-color: var(--background);
}

.material-symbols-outlined {
    font-size: inherit;
    color: inherit;
}

i {
    font-style: italic;
}

.layout_base {
    position: relative;
    background-color: var(--background);

    &_content {
        height: inherit;
        width: 100%;
        max-width: 115rem;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow-y: auto;

        .progress-bar {
            background-color: var(--secondary);
        }
    }

    &_content_container {
        display: flex;
        justify-content: center;
        height: calc(100vh - (var(--navbar-height)));
        background: linear-gradient(90deg, var(--background) 50%, var(--button-fade) 50%);
    }

    &_content_menu {
        position: relative;
        height: inherit;
        overflow-y: auto;
        background-color: var(--button-fade);
        > div {
            height: inherit;
            overflow-y: auto;
        }
    }
}

.version_tag {
    position: fixed;
    bottom: 0;
    right: 0;
    margin: 0.5rem 1rem;
    z-index: 1;
}

.loader {
    width: 50%;
    max-width: 12rem;
    animation: redupNyala 1s infinite alternate;
}

.form-control-check {
    display: flex;
    gap: 1rem;
    padding-right: 3rem;

    >:not(.form-control) {
        z-index: 2;
    }

    .form-control {
        position: absolute;
        top: 0;
        right: 0;
        margin-top: -0.25rem;
        padding-top: 0;
        color: transparent;
        border: none;
        &:valid, &:invalid {
            border-bottom: none;
        }
    }
}

@keyframes redupNyala {
    0% {
        opacity: 0.3;
    }
    100% {
        opacity: 1;
    }
}

@media screen and (min-width: 769px) {
    .layout_base {
        &_content_menu {
            flex: 1;
        }
    }
}

@media screen and (max-width: 768px) {
    .layout_base {
        &_content_menu {
            width: calc(100% - 3.5rem);
            margin-left: 3.5rem;
        }
    }
}
